import { render, staticRenderFns } from "./MediaCustomCard.vue?vue&type=template&id=ba4aca90&scoped=true"
import script from "./MediaCustomCard.vue?vue&type=script&lang=js"
export * from "./MediaCustomCard.vue?vue&type=script&lang=js"
import style0 from "./MediaCustomCard.vue?vue&type=style&index=0&id=ba4aca90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba4aca90",
  null
  
)

export default component.exports