var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card border-0 border-round-10 card-shadow carousel-custom-item",class:{
    'type-bannerV': _vm.isItemBannerV,
    'type-bannerH': _vm.isItemBannerH,
    'type-video': _vm.isItemVideo,
  }},[_c('div',{staticClass:"card-body h-100"},[(_vm.isItemVideo && _vm.isVideo(_vm.item?.url_archivo || _vm.item?.url_video))?_c('VueVideoThumbnail',{staticClass:"img-fluid img-obj card-item-add-img rounded",attrs:{"video-src":_vm.item?.url_archivo || _vm.dataURL || _vm.item?.url_video,"width":170,"height":100,"snapshot-at-duration-percent":1,"chunks-quantity":1}}):_c('img',{staticClass:"border-round-10 carousel-custom-item-img",attrs:{"src":_vm.dataURL || _vm.item[_vm.thumbnailField]}}),(_vm.removable)?_c('div',{staticClass:"carousel-custom-item-remove",class:{ 'remove-thumbnail-image': !_vm.isItemVideo }},[(_vm.isItemVideo && _vm.isVideo(_vm.item?.url_archivo || _vm.item?.url_video))?_c('span',{staticClass:"carousel-custom-item-remove-time"},[_vm._v(" "+_vm._s(_vm.item?.tiempo_video)+" ")]):_vm._e(),(
          !(_vm.isItemVideo && _vm.isVideo(_vm.item?.url_archivo || _vm.item?.url_video)) &&
          !_vm.isItemBannerV &&
          !_vm.isItemBannerH
        )?_c('span',{staticClass:"carousel-custom-item-remove-time"},[_vm._v(_vm._s(_vm.time))]):_vm._e(),_c('a',{staticClass:"carousel-custom-item-remove-icon",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.deleteItem(_vm.index)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]):_vm._e(),(_vm.isItemVideo)?_c('div',{staticClass:"carousel-custom-item-title"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.item?.titulo_video))+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }