<template>
  <section id="videos-sel-muro-digital" class="mt-3" >
    <h5 class="font-main-bold text-secondary border-bottom mb-0 pb-1">
      Lista de reproducción
    </h5>
    <div class="alert alert-light m-0">
      <strong class="font-main-bold">Revisa, ordena o elimina</strong> {{ itemType }} añadidos.
    </div>

    <div class="row">
      <div class="col-12">
        <Draggable 
          class="carousel-custom"
          :animation="200"
          :list="selected_items"
          :key="draggableComponentKey"
          @start="drag = true" @end="drag = false"
        >
          <template v-for="(item, i) in selected_items">
            <MediaCustomCard
              :key="'draggable-list-item'+i"
              :index="i"
              :item="item"
              :dataURL="item.url_archivo"
              :thumbnailField="thumbnailField"
              @deleteItem="deleteItemFromList"
            />
          </template>
        </Draggable>
      </div>
    </div>

  </section>
</template>

<script>
import Draggable from "vuedraggable";
import MediaCustomCard from "./MediaCustomCard.vue";
import { mapState } from "vuex";

export default {
  components: { 
    Draggable, 
    MediaCustomCard
   },
  props: {
    selected_items: Array,
    thumbnailField: String
  },
  
  data() {
    return {
      draggableComponentKey: 0
    };
  },

  watch: {
    selected_items() {
      this.draggableComponentKey++;
    }
  },

  computed: {
    ...mapState("tvCorporativaModule", ["imagesListTime"]),
    isVerticalBanner() {
      return this.$route.name === "CanalCorporativo-listadoBanners";
    },
    isItemTypeBanner() {
      return this.$route.name === "CanalCorporativo-listadoBanners" ||
        this.$route.name === "CanalCorporativo-listadoBannersHorizontal";
    },
    isItemTypeVideo() {
      return this.$route.name === "CanalCorporativo-listadoVideos";
    },
    itemType() {
      if (this.$route.name === "CanalCorporativo-listadoBanners" ||
        this.$route.name === "CanalCorporativo-listadoBannersHorizontal") {
        return "los banners";
      } else {
        return "las imágenes y videos";
      }
    },
  },
  
  mounted() {
    let eventSidebar = document.querySelector(".sidebar-narrow-link");
    eventSidebar.addEventListener('click', this.resizeCarousel);  
    window.addEventListener('resize', this.resizeCarousel); 
  },

  updated() {
    this.resizeCarousel();
  },

  methods: {
    deleteItemFromList(index) {
      this.$emit("deleteItemFromList", index);
    },
  
    resizeCarousel() {
      let sidebarNarrow = document.querySelector(".sidebar-narrow");
      let carousel = document.querySelector(".carousel-custom");
      if (sidebarNarrow){
        carousel.classList.add('carousel-custom-narrow');
      }
      else {
        carousel.classList.remove('carousel-custom-narrow')
      }
    }
  },

  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>